export const locationBySiteIdMock = {
  locationid: 129682,
  importcode: "36752",
  competitor: false,
  description: "36752 - 110 W CENTER ST  84057",
  icon: "711.png",
  brand: "7-ELEVEN",
  opisId: "648325",
  priceSource: "PCF",
  prices: [
    {
      productName: "Regular",
      retail: 2.959,
      effectiveDate: "2024-11-24 08:10:00",
    },
    {
      productName: "Plus",
      retail: 3.299,
      effectiveDate: "2024-11-24 08:10:00",
    },
    {
      productName: "Supreme",
      retail: 3.599,
      effectiveDate: "2024-11-24 08:10:00",
    },
    {
      productName: "Diesel",
      retail: 3.379,
      effectiveDate: "2024-11-22 09:30:00",
    },
  ],
  opisPrices: [
    {
      productName: "Regular",
      retail: 2.959,
      effectiveDate: "2024-11-24 20:19:31",
    },
    {
      productName: "Plus",
      retail: 3.299,
      effectiveDate: "2024-11-24 20:19:31",
    },
    {
      productName: "Supreme",
      retail: 3.599,
      effectiveDate: "2024-11-24 20:19:31",
    },
    {
      productName: "Diesel",
      retail: 3.379,
      effectiveDate: "2024-11-24 20:19:31",
    },
  ],
};

export const getPriceCompareDataMock = {
  took: 6,
  timed_out: false,
  _shards: {
    total: 1,
    successful: 1,
    skipped: 0,
    failed: 0,
  },
  hits: {
    total: {
      value: 8,
      relation: "eq",
    },
    max_score: null,
    hits: [
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "OMQt_5IBmFEG8YmEryVg",
        _score: null,
        _source: {
          importcode: "36752",
          isCompetitor: false,
          displayname: "36752 - 110 W CENTER ST  84057",
          location: {
            lon: -111.69816,
            lat: 40.29743,
          },
          brandname: "7-ELEVEN",
        },
        sort: [0.0],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "8qgr_5IBPV7-moj6wPkq",
        _score: null,
        _source: {
          importcode: "36752-HOLIDAY",
          isCompetitor: true,
          displayname: "36752-HOLIDAY OIL #15",
          location: {
            lon: -111.70232,
            lat: 40.29714,
          },
          brandname: "SINCLAIR",
        },
        sort: [0.22013583950735208],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "76gr_5IBPV7-moj6wPkq",
        _score: null,
        _source: {
          importcode: "36752-SMITH'S FOODS",
          isCompetitor: true,
          displayname: "36752-SMITH'S FOODS",
          location: {
            lon: -111.6926,
            lat: 40.296753,
          },
          brandname: "SMITHS",
        },
        sort: [0.29670839087475215],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "vMQ-_5IBmFEG8YmEzFHv",
        _score: null,
        _source: {
          importcode: "479976",
          isCompetitor: true,
          displayname: "479976 - Fast Gas #4",
          location: {
            lon: -111.70038,
            lat: 40.304268,
          },
          brandname: "Exxon",
        },
        sort: [0.48672765184109096],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "wMQ-_5IBmFEG8YmEzFHv",
        _score: null,
        _source: {
          importcode: "425774",
          isCompetitor: true,
          displayname: "425774 - Last Chance",
          location: {
            lon: -111.6987,
            lat: 40.306538,
          },
          brandname: "SHELL",
        },
        sort: [0.629944546965743],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "ragr_5IBPV7-moj6uPlB",
        _score: null,
        _source: {
          importcode: "36451-CHEVRON",
          isCompetitor: true,
          displayname: "36451-EXTRA MILE (Jacksons)",
          location: {
            lon: -111.70201,
            lat: 40.3115,
          },
          brandname: "Chevron",
        },
        sort: [0.9930846543850607],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "JX8t_5IB7YXbMMhlnw6L",
        _score: null,
        _source: {
          importcode: "36451",
          isCompetitor: false,
          displayname: "36451 - 800 N. MAIN ST  84057",
          location: {
            lon: -111.69597,
            lat: 40.31177,
          },
          brandname: "7-ELEVEN",
        },
        sort: [0.997494088587264],
      },
      {
        _index: "geolocation",
        _type: "_doc",
        _id: "qagr_5IBPV7-moj6uPlB",
        _score: null,
        _source: {
          importcode: "36451-MAVERIK",
          isCompetitor: true,
          displayname: "36451-MAVERIK #339 REOPEN 5/30/24",
          location: {
            lon: -111.69785,
            lat: 40.31187,
          },
          brandname: "MAVERIK",
        },
        sort: [0.9978399157782233],
      },
    ],
  },
};

export const findLocationProductPriceMock = [
  {
    importcode: "36451-MAVERIK",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 3.299,
        effectivedate: "2023-11-21 09:29:00",
        averageLastXDays: null,
      },
      {
        product: "Plus",
        currentPrice: 3.499,
        effectivedate: "2023-11-21 08:22:00",
        averageLastXDays: null,
      },
      {
        product: "Supreme",
        currentPrice: 3.699,
        effectivedate: "2023-11-20 21:52:00",
        averageLastXDays: null,
      },
      {
        product: "Diesel",
        currentPrice: 4.199,
        effectivedate: "2023-11-20 21:52:00",
        averageLastXDays: null,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.999,
        effectivedate: "2024-11-25 03:57:24",
        averageLastXDays: 3.053,
      },
      {
        product: "Plus",
        currentPrice: 3.199,
        effectivedate: "2024-11-25 03:57:52",
        averageLastXDays: 3.252,
      },
      {
        product: "Supreme",
        currentPrice: 3.399,
        effectivedate: "2024-11-25 03:53:01",
        averageLastXDays: 3.454,
      },
      {
        product: "Diesel",
        currentPrice: 3.259,
        effectivedate: "2024-11-25 03:53:01",
        averageLastXDays: 3.339,
      },
    ],
  },
  {
    importcode: "36752-HOLIDAY",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 3.699,
        effectivedate: "2024-04-09 17:22:00",
        averageLastXDays: null,
      },
      {
        product: "Plus",
        currentPrice: 4.089,
        effectivedate: "2024-04-05 07:53:00",
        averageLastXDays: null,
      },
      {
        product: "Supreme",
        currentPrice: 4.289,
        effectivedate: "2024-04-08 07:04:00",
        averageLastXDays: null,
      },
      {
        product: "Diesel",
        currentPrice: 3.959,
        effectivedate: "2024-04-09 08:38:00",
        averageLastXDays: null,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.959,
        effectivedate: "2024-11-25 03:38:55",
        averageLastXDays: 3.036,
      },
      {
        product: "Plus",
        currentPrice: 3.249,
        effectivedate: "2024-11-22 02:18:10",
        averageLastXDays: 3.348,
      },
      {
        product: "Supreme",
        currentPrice: 3.599,
        effectivedate: "2024-11-21 13:52:45",
        averageLastXDays: 3.616,
      },
      {
        product: "Diesel",
        currentPrice: 3.259,
        effectivedate: "2024-11-24 13:52:18",
        averageLastXDays: 3.395,
      },
    ],
  },
  {
    importcode: "36451",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 2.999,
        effectivedate: "2024-11-21 18:10:00",
        averageLastXDays: 3.067,
      },
      {
        product: "Plus",
        currentPrice: 3.349,
        effectivedate: "2024-11-21 18:10:00",
        averageLastXDays: 3.417,
      },
      {
        product: "Supreme",
        currentPrice: 3.649,
        effectivedate: "2024-11-21 18:10:00",
        averageLastXDays: 3.717,
      },
      {
        product: "Diesel",
        currentPrice: 3.379,
        effectivedate: "2024-11-21 20:10:00",
        averageLastXDays: 3.329,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.999,
        effectivedate: "2024-11-24 19:00:07",
        averageLastXDays: 3.088,
      },
      {
        product: "Plus",
        currentPrice: 3.349,
        effectivedate: "2024-11-24 19:00:07",
        averageLastXDays: 3.429,
      },
      {
        product: "Supreme",
        currentPrice: 3.649,
        effectivedate: "2024-11-24 19:00:07",
        averageLastXDays: 3.732,
      },
      {
        product: "Diesel",
        currentPrice: 3.399,
        effectivedate: "2024-11-23 19:56:17",
        averageLastXDays: 3.385,
      },
    ],
  },
  {
    importcode: "36451-CHEVRON",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 3.929,
        effectivedate: "2024-04-09 17:45:00",
        averageLastXDays: null,
      },
      {
        product: "Plus",
        currentPrice: 4.099,
        effectivedate: "2024-04-04 16:51:00",
        averageLastXDays: null,
      },
      {
        product: "Supreme",
        currentPrice: 4.299,
        effectivedate: "2024-04-02 06:11:00",
        averageLastXDays: null,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 3.029,
        effectivedate: "2024-11-24 13:19:27",
        averageLastXDays: 3.105,
      },
      {
        product: "Plus",
        currentPrice: 3.229,
        effectivedate: "2024-11-24 07:05:17",
        averageLastXDays: 3.296,
      },
      {
        product: "Supreme",
        currentPrice: 3.429,
        effectivedate: "2024-11-24 20:28:16",
        averageLastXDays: 3.488,
      },
    ],
  },
  {
    importcode: "425774",
    listOfProduct: [],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 3.099,
        effectivedate: "2024-11-21 07:06:24",
        averageLastXDays: 3.179,
      },
      {
        product: "Plus",
        currentPrice: 3.799,
        effectivedate: "2024-10-10 06:29:51",
        averageLastXDays: null,
      },
      {
        product: "Supreme",
        currentPrice: 3.999,
        effectivedate: "2024-10-10 06:29:51",
        averageLastXDays: null,
      },
      {
        product: "Diesel",
        currentPrice: 3.499,
        effectivedate: "2024-11-12 15:14:45",
        averageLastXDays: null,
      },
    ],
  },
  {
    importcode: "36752",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 2.959,
        effectivedate: "2024-11-24 08:10:00",
        averageLastXDays: 3.046,
      },
      {
        product: "Plus",
        currentPrice: 3.299,
        effectivedate: "2024-11-24 08:10:00",
        averageLastXDays: 3.396,
      },
      {
        product: "Supreme",
        currentPrice: 3.599,
        effectivedate: "2024-11-24 08:10:00",
        averageLastXDays: 3.696,
      },
      {
        product: "Diesel",
        currentPrice: 3.379,
        effectivedate: "2024-11-22 09:30:00",
        averageLastXDays: 3.324,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.959,
        effectivedate: "2024-11-24 20:19:31",
        averageLastXDays: 3.054,
      },
      {
        product: "Plus",
        currentPrice: 3.299,
        effectivedate: "2024-11-24 20:19:31",
        averageLastXDays: 3.421,
      },
      {
        product: "Supreme",
        currentPrice: 3.599,
        effectivedate: "2024-11-24 20:19:31",
        averageLastXDays: 3.716,
      },
      {
        product: "Diesel",
        currentPrice: 3.379,
        effectivedate: "2024-11-24 20:19:31",
        averageLastXDays: 3.355,
      },
    ],
  },
  {
    importcode: "36752-SMITH'S FOODS",
    listOfProduct: [
      {
        product: "Regular",
        currentPrice: 3.899,
        effectivedate: "2024-04-09 11:50:00",
        averageLastXDays: null,
      },
      {
        product: "Plus",
        currentPrice: 3.989,
        effectivedate: "2024-04-08 07:04:00",
        averageLastXDays: null,
      },
      {
        product: "Supreme",
        currentPrice: 4.199,
        effectivedate: "2024-04-08 07:04:00",
        averageLastXDays: null,
      },
      {
        product: "Diesel",
        currentPrice: 3.999,
        effectivedate: "2024-04-08 07:04:00",
        averageLastXDays: null,
      },
    ],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.959,
        effectivedate: "2024-11-24 20:19:20",
        averageLastXDays: 3.009,
      },
      {
        product: "Plus",
        currentPrice: 3.249,
        effectivedate: "2024-11-24 20:19:20",
        averageLastXDays: 3.249,
      },
      {
        product: "Supreme",
        currentPrice: 3.449,
        effectivedate: "2024-11-24 20:19:20",
        averageLastXDays: 3.449,
      },
      {
        product: "Diesel",
        currentPrice: 3.259,
        effectivedate: "2024-11-24 20:19:20",
        averageLastXDays: 3.321,
      },
    ],
  },
  {
    importcode: "479976",
    listOfProduct: [],
    listOfProductOpis: [
      {
        product: "Regular",
        currentPrice: 2.999,
        effectivedate: "2024-11-22 16:59:05",
        averageLastXDays: 3.078,
      },
      {
        product: "Plus",
        currentPrice: 3.199,
        effectivedate: "2024-11-21 15:33:40",
        averageLastXDays: 3.299,
      },
      {
        product: "Supreme",
        currentPrice: 3.599,
        effectivedate: "2024-11-13 13:34:19",
        averageLastXDays: null,
      },
      {
        product: "Diesel",
        currentPrice: 3.199,
        effectivedate: "2024-11-19 12:56:15",
        averageLastXDays: 3.299,
      },
    ],
  },
];
