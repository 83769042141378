import moment, { Moment } from "moment";
import { StoreTransactionDetails } from "../types/StoreTransactionDetails";
import { TransactionInfo } from "../types/TransactionInfo";

const readableDateFormat = "MM/DD/YY HH:mm:ss";
const requestDateTime = "YYYY-MM-DDTHH:mm";

export function obtainFuelsTransaction(
  data: StoreTransactionDetails[]
): TransactionInfo[] {
  const flattenedData: TransactionInfo[] = data.map((txn) => {
    const { tenderTransactions, fuelTransactions, ...rest } = txn;
    return {
      ...rest,
      parentCreatedDate: rest.createdDate,
      ...txn.fuelTransactions[0],
      ...txn.tenderTransactions[0],
    };
  });

  return flattenedData;
}

export function convertingTransactionDates(
  transactionInfoList: TransactionInfo[]
) {
  return transactionInfoList.map((fuelTxn) => {
    const { beginDateTime, createdDate, parentCreatedDate, ...rest } = fuelTxn;
    return {
      beginDateTime: formatDate(beginDateTime),
      createdDate: formatDate(createdDate),
      parentCreatedDate: formatDate(parentCreatedDate),
      ...rest,
    };
  });
}

export function formatDate(date: string) {
  const formatDate = String(moment(date).format(readableDateFormat));
  return formatDate;
}

export function formatDateForApiCall(date: Moment | null) {
  const formatDate = String(date.format(requestDateTime));
  return formatDate;
}
