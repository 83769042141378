export const getStoreDetailsMock = {
  storeId: "36752",
  locationDetails: {
    coordinates: {
      latitude: "40.29743",
      longitude: "-111.69816",
    },
    storeId: "36752",
    name: "36752 - 110 W CENTER ST  84057",
    description: "36752 - 110 W CENTER ST  84057",
    brand: "41",
    icon: "11",
    isCompetitor: false,
    enableOPISFeed: true,
    opisId: "648325",
  },
  address: {
    streetAddress: "110 W Center St ",
    city: "OREM",
    state: "UT",
    country: "US",
    zipCode: "84057",
    timeZone: "MST",
    latitude: "40.29743",
    longitude: "-111.69816",
    county: "UTAH",
  },
  posParameters: {
    posType: "DEX",
    posVersion: "DEX V2.1.0.1",
    priceSignIntegration: "PRICEVISION",
    autoInit: true,
  },
  connectionParameters: {
    ipDnsName: "DEX Cloud",
    userName: "DEX",
    password: "DEX",
    connectionType: "http",
    port: "24",
    inBoundPath: "NULL",
    outBoundPath: "NULL",
  },
  networkParameters: null,
  storeProductMapping: {
    productMapping: [
      {
        productId: "001",
        storeProductId: "001",
        storeProductName: "RUL",
      },
      {
        productId: "002",
        storeProductId: "073",
        storeProductName: "NMB",
      },
      {
        productId: "003",
        storeProductId: "003",
        storeProductName: "PUL",
      },
      {
        productId: "019",
        storeProductId: "019",
        storeProductName: "DSL",
      },
    ],
    sameForTransactionProductMapping: false,
    transactionProductMapping: [
      {
        productId: "001",
        storeProductId: "1",
        storeProductName: "RUL",
      },
      {
        productId: "002",
        storeProductId: "73",
        storeProductName: "NMB",
      },
      {
        productId: "003",
        storeProductId: "3",
        storeProductName: "PUL",
      },
      {
        productId: "019",
        storeProductId: "19",
        storeProductName: "DSL",
      },
    ],
  },
  priceUpdateParameters: [],
  storeAlertConfig: [
    {
      alertId: 1,
      email: "dist-fuelspricing@7-11.com",
      name: "DIST-FuelsPricing",
    },
  ],
  storeAlertScheduleConfig: {
    infoPUSchd: false,
    actionPUSchd: false,
    schdTime: [],
  },
  businessFunctions: [
    {
      businessFunctionCode: "PostToA2I",
      status: "true",
      recordStatus: null,
    },
    {
      businessFunctionCode: "PriceUpdate",
      status: "true",
      recordStatus: null,
    },
    {
      businessFunctionCode: "PriceUpdateAck",
      status: "true",
      recordStatus: null,
    },
    {
      businessFunctionCode: "Transaction",
      status: "true",
      recordStatus: null,
    },
    {
      businessFunctionCode: "PostACKToA2I",
      status: "true",
      recordStatus: null,
    },
  ],
  businessParameters: {
    businessOrg: null,
    market: "Arizona/South Utah",
    zone: "Southwest",
    businessType: "Franchisee",
    alternateId: "36752",
    analystUserId: "Dakota Helphenstine",
    managerUserId: "Abby Petrullo",
  },
};

export const getIconsMock = [
  {
    iconid: "11",
    filename: "711.png",
  },
  {
    iconid: "47",
    filename: "76.png",
  },
  {
    iconid: "35",
    filename: "alert.png",
  },
  {
    iconid: "1",
    filename: "allsups.png",
  },
  {
    iconid: "66",
    filename: "alltownmarkets.png",
  },
  {
    iconid: "67",
    filename: "ampm.png",
  },
  {
    iconid: "45",
    filename: "Arco.png",
  },
  {
    iconid: "68",
    filename: "atlantisfreshmarket.png",
  },
  {
    iconid: "14",
    filename: "aziz.png",
  },
  {
    iconid: "69",
    filename: "bfsfoods.png",
  },
  {
    iconid: "70",
    filename: "bj.png",
  },
  {
    iconid: "71",
    filename: "bollamarket.png",
  },
  {
    iconid: "21",
    filename: "bolton.png",
  },
  {
    iconid: "53",
    filename: "bp.png",
  },
  {
    iconid: "44",
    filename: "bucees.png",
  },
  {
    iconid: "54",
    filename: "caseys.png",
  },
  {
    iconid: "72",
    filename: "cefco.png",
  },
  {
    iconid: "73",
    filename: "cenex.png",
  },
  {
    iconid: "2",
    filename: "chevron.png",
  },
  {
    iconid: "46",
    filename: "Circle_K.png",
  },
  {
    iconid: "27",
    filename: "citgo.png",
  },
  {
    iconid: "31",
    filename: "conoco.png",
  },
  {
    iconid: "49",
    filename: "costco.png",
  },
  {
    iconid: "74",
    filename: "cumberland.png",
  },
  {
    iconid: "75",
    filename: "dashinfoods.png",
  },
  {
    iconid: "76",
    filename: "delta.png",
  },
  {
    iconid: "77",
    filename: "dillon.png",
  },
  {
    iconid: "78",
    filename: "dk.png",
  },
  {
    iconid: "15",
    filename: "eltigre.png",
  },
  {
    iconid: "79",
    filename: "extramile.png",
  },
  {
    iconid: "25",
    filename: "exxon.png",
  },
  {
    iconid: "30",
    filename: "ezgo.png",
  },
  {
    iconid: "80",
    filename: "ezmart.png",
  },
  {
    iconid: "81",
    filename: "familyexpress.png",
  },
  {
    iconid: "82",
    filename: "fasmart.png",
  },
  {
    iconid: "83",
    filename: "fastfresh.png",
  },
  {
    iconid: "33",
    filename: "fina.png",
  },
  {
    iconid: "84",
    filename: "fivestarfood.png",
  },
  {
    iconid: "23",
    filename: "flyingJ.png",
  },
  {
    iconid: "85",
    filename: "fredmeyer.png",
  },
  {
    iconid: "86",
    filename: "frys.png",
  },
  {
    iconid: "87",
    filename: "getgo.png",
  },
  {
    iconid: "88",
    filename: "giant.png",
  },
  {
    iconid: "89",
    filename: "gomart.png",
  },
  {
    iconid: "90",
    filename: "goodtogo.png",
  },
  {
    iconid: "91",
    filename: "greenvalleygrocery.png",
  },
  {
    iconid: "41",
    filename: "Gulf.png",
  },
  {
    iconid: "92",
    filename: "harristeeter.png",
  },
  {
    iconid: "3",
    filename: "heb.png",
  },
  {
    iconid: "93",
    filename: "holiday.png",
  },
  {
    iconid: "94",
    filename: "irving.png",
  },
  {
    iconid: "95",
    filename: "jacksons.png",
  },
  {
    iconid: "96",
    filename: "kentkwik.png",
  },
  {
    iconid: "98",
    filename: "kimgo.png",
  },
  {
    iconid: "97",
    filename: "kingsoopers.png",
  },
  {
    iconid: "28",
    filename: "kroger.png",
  },
  {
    iconid: "99",
    filename: "kwikfill.png",
  },
  {
    iconid: "100",
    filename: "kwikshop.png",
  },
  {
    iconid: "101",
    filename: "kwikstar.png",
  },
  {
    iconid: "102",
    filename: "kwiktrip.png",
  },
  {
    iconid: "103",
    filename: "littlegeneral.png",
  },
  {
    iconid: "104",
    filename: "loafjug.png",
  },
  {
    iconid: "34",
    filename: "loves.png",
  },
  {
    iconid: "22",
    filename: "lowes.png",
  },
  {
    iconid: "105",
    filename: "mapco.png",
  },
  {
    iconid: "55",
    filename: "marathon.png",
  },
  {
    iconid: "50",
    filename: "maverik.png",
  },
  {
    iconid: "56",
    filename: "meijer.png",
  },
  {
    iconid: "106",
    filename: "minitmart.png",
  },
  {
    iconid: "26",
    filename: "mobil.png",
  },
  {
    iconid: "18",
    filename: "murphy.png",
  },
  {
    iconid: "107",
    filename: "nouria.png",
  },
  {
    iconid: "109",
    filename: "oncue.png",
  },
  {
    iconid: "110",
    filename: "one9truckshop.png",
  },
  {
    iconid: "108",
    filename: "onetherun.png",
  },
  {
    iconid: "111",
    filename: "parkers.png",
  },
  {
    iconid: "112",
    filename: "petes.png",
  },
  {
    iconid: "65",
    filename: "petrocanada.png",
  },
  {
    iconid: "113",
    filename: "petromart.png",
  },
  {
    iconid: "32",
    filename: "phillips66.png",
  },
  {
    iconid: "29",
    filename: "pump-n-shop.png",
  },
  {
    iconid: "114",
    filename: "quickcheck.png",
  },
  {
    iconid: "19",
    filename: "quiktrip.png",
  },
  {
    iconid: "17",
    filename: "racetrac.png",
  },
  {
    iconid: "36",
    filename: "raceway.png",
  },
  {
    iconid: "115",
    filename: "rebel.png",
  },
  {
    iconid: "116",
    filename: "refuel.png",
  },
  {
    iconid: "117",
    filename: "roadranger.png",
  },
  {
    iconid: "118",
    filename: "royalfarms.png",
  },
  {
    iconid: "119",
    filename: "rutters.png",
  },
  {
    iconid: "40",
    filename: "sacNpac.png",
  },
  {
    iconid: "57",
    filename: "safeway.png",
  },
  {
    iconid: "37",
    filename: "sams_club.png",
  },
  {
    iconid: "63",
    filename: "sheetz.png",
  },
  {
    iconid: "24",
    filename: "shell.png",
  },
  {
    iconid: "120",
    filename: "shorestop.png",
  },
  {
    iconid: "51",
    filename: "sinclair.png",
  },
  {
    iconid: "4",
    filename: "skinnys.png",
  },
  {
    iconid: "121",
    filename: "smiths.png",
  },
  {
    iconid: "12",
    filename: "snappy.png",
  },
  {
    iconid: "48",
    filename: "speedway.png",
  },
  {
    iconid: "13",
    filename: "speedystop.png",
  },
  {
    iconid: "122",
    filename: "spinx.png",
  },
  {
    iconid: "123",
    filename: "sprintmart.png",
  },
  {
    iconid: "124",
    filename: "stewart.png",
  },
  {
    iconid: "125",
    filename: "stinker.png",
  },
  {
    iconid: "126",
    filename: "stopshop.png",
  },
  {
    iconid: "5",
    filename: "stripes.png",
  },
  {
    iconid: "43",
    filename: "stripes_sunoco.png",
  },
  {
    iconid: "39",
    filename: "stripes_truckstop.PNG",
  },
  {
    iconid: "42",
    filename: "sunoco.png",
  },
  {
    iconid: "20",
    filename: "taylor.png",
  },
  {
    iconid: "9",
    filename: "tcfs.png",
  },
  {
    iconid: "127",
    filename: "terribleherbst.png",
  },
  {
    iconid: "128",
    filename: "tesoro.png",
  },
  {
    iconid: "38",
    filename: "TETCO.png",
  },
  {
    iconid: "16",
    filename: "texaco.png",
  },
  {
    iconid: "62",
    filename: "thorntons.png",
  },
  {
    iconid: "129",
    filename: "tootntotum.png",
  },
  {
    iconid: "130",
    filename: "topstop.png",
  },
  {
    iconid: "131",
    filename: "travelcenters.png",
  },
  {
    iconid: "132",
    filename: "turkeyhill.png",
  },
  {
    iconid: "133",
    filename: "twicedaily.png",
  },
  {
    iconid: "59",
    filename: "united_Dairy_Farmers.png",
  },
  {
    iconid: "10",
    filename: "united.png",
  },
  {
    iconid: "6",
    filename: "unknown.png",
  },
  {
    iconid: "7",
    filename: "valero.png",
  },
  {
    iconid: "61",
    filename: "vons.png",
  },
  {
    iconid: "8",
    filename: "walmart.png",
  },
  {
    iconid: "64",
    filename: "wawa.png",
  },
  {
    iconid: "134",
    filename: "weigel.png",
  },
];

export const getTransactionDetailsByIntervalMock = [
  {
    txnGUID: "9d1ba8ac-203e-4275-afe0-477631894215",
    storeID: "36752",
    transactionID: "741527ec-2dcd-48f0-9a07-93d6a42a0a58",
    businessDate: 1732514400000,
    lastModifiedDate: "2024-11-25T02:55:16.062",
    createdDate: "2024-11-25T02:54:01.364",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-25T01:50:41.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-25T01:53:26.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "33581613-4847-4142-8abb-d8b179f1c1af",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.249,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 9,
        fuelgrade: "73",
        salesamount: 46.5,
        salescurrency: "USD",
        salesquantity: 14.312,
        salesuom: "gal",
        lastModifiedDate: "2024-11-25T02:54:01.366",
        createdDate: "2024-11-25T02:54:01.366",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "0d3e54ab-85c6-4c6d-862f-b90d15bdacf2",
        tenderamount: 46.5,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit_Loyalty",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-25T00:00:00.000",
        createdDate: "2024-11-25T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "c9be34a1-2516-465e-9e7d-6c7efd5ba72a",
    storeID: "36752",
    transactionID: "57623030-4bad-4f6c-8694-fa7190b6e722",
    businessDate: 1732514400000,
    lastModifiedDate: "2024-11-25T02:15:14.806",
    createdDate: "2024-11-25T02:10:52.290",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-25T01:08:45.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-25T01:10:05.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "5e34f00d-0bd0-429f-81c5-2dd5ca399f69",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.909,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 5,
        fuelgrade: "1",
        salesamount: 7.01,
        salescurrency: "USD",
        salesquantity: 2.411,
        salesuom: "gal",
        lastModifiedDate: "2024-11-25T02:10:52.292",
        createdDate: "2024-11-25T02:10:52.292",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "ac1cd3d2-6063-4de6-8c59-cc05eb9f3639",
        tenderamount: 7.01,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit_Loyalty",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-25T00:00:00.000",
        createdDate: "2024-11-25T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "7ebe5c53-d596-46e4-9dc0-fdd9b75657fb",
    storeID: "36752",
    transactionID: "333200ee-8c41-42ea-88d2-107606a56017",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T23:30:16.433",
    createdDate: "2024-11-24T23:26:04.338",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T22:22:12.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T22:25:32.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "64da24ae-ffc7-452e-9a0e-79c4b3621195",
        fueldescription: "PUL",
        fuelcode: 0,
        actualsalespriceamount: 3.599,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.599,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 5,
        fuelgrade: "3",
        salesamount: 43.28,
        salescurrency: "USD",
        salesquantity: 12.026,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T23:26:04.340",
        createdDate: "2024-11-24T23:26:04.340",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "f2ce6504-c829-4d98-b7c8-e7631cdfce99",
        tenderamount: 43.28,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit_Loyalty",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "01724338-1cca-4fb2-8d58-63b1c65261fa",
    storeID: "36752",
    transactionID: "117fe775-9ada-4ca2-9e8f-0d3b14b6e473",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T23:20:23.320",
    createdDate: "2024-11-24T23:17:56.439",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T22:14:58.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T22:17:21.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "b39dc889-48f9-4a03-a43a-cc6d20f861d4",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 9,
        fuelgrade: "1",
        salesamount: 42.74,
        salescurrency: "USD",
        salesquantity: 14.444,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T23:17:56.441",
        createdDate: "2024-11-24T23:17:56.441",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "4611be47-c93d-473a-9537-af314c89eaa7",
        tenderamount: 42.74,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "f7495813-6221-4102-9859-37a8ae3fc12d",
    storeID: "36752",
    transactionID: "2e28e0b8-d69e-4ff3-9ac5-b8b6653459d5",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T22:51:28.204",
    createdDate: "2024-11-24T22:45:51.363",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T21:41:08.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T21:44:10.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "084a42c1-7a28-4b72-964c-7125de3b5ef6",
        fueldescription: "PUL",
        fuelcode: 0,
        actualsalespriceamount: 3.549,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.599,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 2,
        fuelgrade: "3",
        salesamount: 50.39,
        salescurrency: "USD",
        salesquantity: 14.198,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T22:45:51.364",
        createdDate: "2024-11-24T22:45:51.364",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "373c7b83-9145-4ab4-8aea-8913e44df788",
        tenderamount: 50.39,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit_Loyalty",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "9a9ab984-e284-41e6-bded-7e74497ccb2e",
    storeID: "36752",
    transactionID: "1f391fe0-ac9f-467a-a42e-b8a0bc5d4040",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T22:51:28.204",
    createdDate: "2024-11-24T22:44:01.856",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T21:40:12.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T21:42:48.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "ac2acc32-9f6c-45d0-9fa7-a2d4001c3cd0",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.299,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 5,
        fuelgrade: "73",
        salesamount: 45.46,
        salescurrency: "USD",
        salesquantity: 13.779,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T22:44:01.857",
        createdDate: "2024-11-24T22:44:01.857",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "e2eca606-493b-451f-9a77-4f85176e2d3c",
        tenderamount: 45.46,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "5bd5b7d7-6bfc-4313-8117-291c2becf224",
    storeID: "36752",
    transactionID: "7ffa57db-d970-4439-a01e-3218ebd19715",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T22:46:32.839",
    createdDate: "2024-11-24T22:27:41.226",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T21:25:13.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T21:26:20.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "ae95db66-1d9b-46f8-9554-4f641eefc875",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.299,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 9,
        fuelgrade: "73",
        salesamount: 15.32,
        salescurrency: "USD",
        salesquantity: 4.645,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T22:27:41.227",
        createdDate: "2024-11-24T22:27:41.227",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "9af51fb1-8318-4e1d-b076-538623071266",
        tenderamount: 15.32,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "7e08b9f1-4281-426c-99e5-295cf7af2c59",
    storeID: "36752",
    transactionID: "244171b3-51ff-462b-b470-dc4f36b37908",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T22:46:32.839",
    createdDate: "2024-11-24T22:24:42.011",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T21:22:33.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T21:24:38.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "221793fa-6925-488d-82ca-8ddf662c4d74",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.249,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 6,
        fuelgrade: "73",
        salesamount: 25.12,
        salescurrency: "USD",
        salesquantity: 7.733,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T22:24:42.012",
        createdDate: "2024-11-24T22:24:42.012",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "03e7ac2d-f3e5-409c-bab2-a2c4ab440d41",
        tenderamount: 25.12,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit_Loyalty",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "4da9f149-3221-4d5c-a495-381298293060",
    storeID: "36752",
    transactionID: "fb13418f-d24c-43a7-94a3-80d056b6164d",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T21:55:40.368",
    createdDate: "2024-11-24T21:51:24.996",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T20:49:05.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T20:50:16.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "1e03cac4-bcb6-4b07-b031-2caa6425a71d",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 3,
        fuelgrade: "1",
        salesamount: 11.0,
        salescurrency: "USD",
        salesquantity: 3.718,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T21:51:24.997",
        createdDate: "2024-11-24T21:51:24.997",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "878a36d5-385f-4609-8caa-7665f17d75a0",
        tenderamount: 11.0,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "f66815c9-b7e8-4490-a83d-347be8824e7e",
    storeID: "36752",
    transactionID: "da821eda-72da-4c2f-a014-f469c5cc9283",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T21:45:33.674",
    createdDate: "2024-11-24T21:43:48.267",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T20:41:21.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T20:42:53.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "087ea1f8-d9b8-4ecf-a742-82862eea2174",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.909,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 2,
        fuelgrade: "1",
        salesamount: 10.37,
        salescurrency: "USD",
        salesquantity: 3.566,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T21:43:48.268",
        createdDate: "2024-11-24T21:43:48.268",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "28a25e0b-06a9-485b-8e11-07758137a246",
        tenderamount: 10.37,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit_Loyalty",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "7ec668ca-83c3-4020-b09e-7ad8e095b1f3",
    storeID: "36752",
    transactionID: "4594853f-69cb-4164-9fd6-c49d817998b8",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T21:35:36.332",
    createdDate: "2024-11-24T21:33:09.240",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T20:28:28.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T20:30:48.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "36566eb9-a002-4aba-8971-6823400dcf3b",
        fueldescription: "PUL",
        fuelcode: 0,
        actualsalespriceamount: 3.599,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.599,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 10,
        fuelgrade: "3",
        salesamount: 44.11,
        salescurrency: "USD",
        salesquantity: 12.256,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T21:33:09.241",
        createdDate: "2024-11-24T21:33:09.241",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "065ac523-4b2a-4421-8562-d1327b1b9a74",
        tenderamount: 44.11,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "d943e133-696c-4bda-ac20-d0278f92e6d3",
    storeID: "36752",
    transactionID: "53ce3e34-d077-4b29-84d7-d0e0222b30bf",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T21:35:36.332",
    createdDate: "2024-11-24T21:33:09.455",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T20:28:01.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T20:30:36.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "6e4e797f-451a-46e0-a8ee-699c76933ab4",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 3,
        fuelgrade: "1",
        salesamount: 37.31,
        salescurrency: "USD",
        salesquantity: 12.609,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T21:33:09.457",
        createdDate: "2024-11-24T21:33:09.457",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "1c0ee5af-a7d0-4196-97bb-c85f0ad5cf51",
        tenderamount: 37.31,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "70756c91-df4d-41d8-a203-578d5fbe7d07",
    storeID: "36752",
    transactionID: "75698344-9f92-4973-8c85-a1075cfa105a",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T21:15:43.988",
    createdDate: "2024-11-24T21:12:45.766",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T20:08:41.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T20:11:13.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "36f8e2c1-4791-41c0-8cff-f052b496db5a",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.299,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 2,
        fuelgrade: "73",
        salesamount: 43.28,
        salescurrency: "USD",
        salesquantity: 13.12,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T21:12:45.768",
        createdDate: "2024-11-24T21:12:45.768",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "cc03c047-7549-49ec-b34e-4457bfa4d9fc",
        tenderamount: 43.28,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "f49ee7df-ce3a-4506-837e-9ef7348368c1",
    storeID: "36752",
    transactionID: "220e3984-61c2-4602-9519-c9873b484ba1",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T20:30:42.301",
    createdDate: "2024-11-24T20:29:39.268",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T19:26:17.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T19:28:32.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "2eefaf2b-4194-41e4-b997-e65fbe4d2454",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.909,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 4,
        fuelgrade: "1",
        salesamount: 29.86,
        salescurrency: "USD",
        salesquantity: 10.266,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T20:29:39.269",
        createdDate: "2024-11-24T20:29:39.269",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "d1b9a95c-9044-44b6-8a09-224cea9600d8",
        tenderamount: 29.86,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit_Loyalty",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit_Loyalty",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "4144eee8-ced4-460f-96e0-0b0b88c915cf",
    storeID: "36752",
    transactionID: "d51410a8-7f99-4674-a436-076a23eb588c",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T20:10:45.802",
    createdDate: "2024-11-24T20:07:51.431",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T19:01:36.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T19:07:08.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "484082fd-d2c6-40ea-bedc-9b7267a7098c",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 10,
        fuelgrade: "1",
        salesamount: 37.04,
        salescurrency: "USD",
        salesquantity: 12.519,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T20:07:51.433",
        createdDate: "2024-11-24T20:07:51.433",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "34000114-4442-438e-8149-b4949acf84f9",
        tenderamount: 37.04,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "39907616-5909-4cba-ab95-d1ffd5927abb",
    storeID: "36752",
    transactionID: "44bf33fd-d132-4d88-86d7-b5aacb7e5926",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T20:05:46.616",
    createdDate: "2024-11-24T20:02:50.121",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T18:59:36.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T19:01:23.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "5a42bba1-eba6-407f-ae01-ad44b6e9aec4",
        fueldescription: "PUL",
        fuelcode: 0,
        actualsalespriceamount: 3.599,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.599,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 6,
        fuelgrade: "3",
        salesamount: 25.13,
        salescurrency: "USD",
        salesquantity: 6.983,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T20:02:50.122",
        createdDate: "2024-11-24T20:02:50.122",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "ed87f7c8-2e39-4247-aac7-e49a1cca5b54",
        tenderamount: 25.13,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "ca68ec94-dbf2-478f-89f9-9b5b45a8db22",
    storeID: "36752",
    transactionID: "7f0caf6c-60d6-4cce-bb04-fef5ea804554",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T19:57:26.763",
    createdDate: "2024-11-24T19:52:46.012",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T18:50:38.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T18:52:05.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "9bcf6c15-7bae-4c90-8445-a3928052d52a",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 5,
        fuelgrade: "1",
        salesamount: 20.33,
        salescurrency: "USD",
        salesquantity: 6.87,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T19:52:46.013",
        createdDate: "2024-11-24T19:52:46.013",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "02dffeb3-05c6-4c04-8b86-5cb9146f0ee1",
        tenderamount: 20.33,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "db83a578-e9dd-4663-bccf-f852cfae0724",
    storeID: "36752",
    transactionID: "164595b5-0793-4ef8-a450-44a19f1e34c9",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T19:57:26.763",
    createdDate: "2024-11-24T19:50:38.266",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T18:47:50.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T18:49:13.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "8529931e-16c0-4c97-af1d-0e900613130f",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 2,
        fuelgrade: "1",
        salesamount: 14.56,
        salescurrency: "USD",
        salesquantity: 4.921,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T19:50:38.267",
        createdDate: "2024-11-24T19:50:38.267",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "3dec4347-1294-4670-ae47-03aed316ae55",
        tenderamount: 14.56,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Credit",
        tendersubcode: "Credit",
        accountid: "XXXXXXX",
        providerId: "PATP_Credit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "2b3ae60d-76c4-40ac-9e8c-0654d9bf9ed3",
    storeID: "36752",
    transactionID: "e0434b4e-6b89-4d7d-905b-188a777db34d",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T19:50:50.967",
    createdDate: "2024-11-24T19:49:33.911",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T18:45:47.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T18:48:45.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "2f96aab3-aa48-44d4-93e7-dc600c825fb0",
        fueldescription: "RUL",
        fuelcode: 0,
        actualsalespriceamount: 2.959,
        actualsalespricecurrency: null,
        regularsalepriceamount: 2.959,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 9,
        fuelgrade: "1",
        salesamount: 40.0,
        salescurrency: "USD",
        salesquantity: 13.519,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T19:49:33.912",
        createdDate: "2024-11-24T19:49:33.912",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "b1f4644e-b7e0-46d9-b677-31a7e5728912",
        tenderamount: 40.0,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
  {
    txnGUID: "637600f2-e35a-4302-b5c2-70d09ef2efb4",
    storeID: "36752",
    transactionID: "9be652e1-761d-4562-ac26-2b411836f673",
    businessDate: 1732428000000,
    lastModifiedDate: "2024-11-24T19:26:06.957",
    createdDate: "2024-11-24T19:23:48.728",
    pricingUID_7eP: null,
    vendorName: "DEX",
    registerID: 0,
    cashierID: "0",
    offlineFlag: "no",
    suspendflag: "no",
    transactionevent: "Sale",
    beginDateTime: "2024-11-24T18:19:27.000",
    a2istatus: "Processed",
    enddatetime: "2024-11-24T18:21:22.000",
    vendormodelversion: "dex_v.1.0",
    outsidesalesflag: "Yes",
    fuelTransactions: [
      {
        fuelTxnID: "fe1ee14b-aa63-4292-b3a4-a617ad11b644",
        fueldescription: "NMB",
        fuelcode: 0,
        actualsalespriceamount: 3.299,
        actualsalespricecurrency: null,
        regularsalepriceamount: 3.299,
        regularsalepricecurrency: "USD",
        transactionlinestatus: "normal",
        dispenserid: 2,
        fuelgrade: "73",
        salesamount: 37.4,
        salescurrency: "USD",
        salesquantity: 11.336,
        salesuom: "gal",
        lastModifiedDate: "2024-11-24T19:23:48.729",
        createdDate: "2024-11-24T19:23:48.729",
      },
    ],
    tenderTransactions: [
      {
        tendertxnid: "ee145cf0-26f5-44fc-a7f2-5d810afc059f",
        tenderamount: 37.4,
        tendercurrency: "USD",
        transactionlinestatus: "normal",
        tendercode: "PATP_Debit",
        tendersubcode: "Debit",
        accountid: "XXXXXXX",
        providerId: "PATP_Debit",
        accountname: "XXXXXXX",
        lastModifiedDate: "2024-11-24T00:00:00.000",
        createdDate: "2024-11-24T00:00:00.000",
      },
    ],
  },
];
