// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --tab-width: 60px;
}

.kpi-dashboard-tabs-container {
  display: flex;
  flex-grow: 1;
  justify-content: start;
  flex-direction: column;
}

.kpi-dashboard-tabs {
  width: var(--tab-width);
  min-width: var(--tab-width);
  overflow-y: auto;
  position: fixed;
  left: 0;
}

.kpi-dashboard-each-tab {
  min-width: 0 !important;
}

.kpi-dashboard-tabs .MuiTab-root:hover {
  transform: scale(1.25);
  transition: transform 0.2s ease;
}

.tabpanel-box {
  padding: 4px 4px 1px 2px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
  margin-bottom: -1rem;
}

.tabpanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  margin-left: var(--tab-width);
  overflow-y: hidden;
}

.kpi-dashboard-tabs .MuiTabs-indicator {
  background-color: rgba(0, 128, 0, 1);
}

.kpi-dashboard-tabs .MuiTab-root {
  color: rgba(0, 0, 0, 0.6);
}

.kpi-dashboard-tabs .MuiTab-root svg {
  color: rgba(0, 0, 0, 0.6);
  font-size: 28px;
}

.kpi-dashboard-tabs .Mui-selected {
  color: rgba(0, 128, 0, 1);
}

.kpi-dashboard-tabs .Mui-selected svg {
  color: rgba(0, 128, 0, 1);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/material-tab/KpiTabs.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,OAAO;AACT;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[":root {\n  --tab-width: 60px;\n}\n\n.kpi-dashboard-tabs-container {\n  display: flex;\n  flex-grow: 1;\n  justify-content: start;\n  flex-direction: column;\n}\n\n.kpi-dashboard-tabs {\n  width: var(--tab-width);\n  min-width: var(--tab-width);\n  overflow-y: auto;\n  position: fixed;\n  left: 0;\n}\n\n.kpi-dashboard-each-tab {\n  min-width: 0 !important;\n}\n\n.kpi-dashboard-tabs .MuiTab-root:hover {\n  transform: scale(1.25);\n  transition: transform 0.2s ease;\n}\n\n.tabpanel-box {\n  padding: 4px 4px 1px 2px;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow-x: auto;\n  margin-bottom: -1rem;\n}\n\n.tabpanel {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  min-width: 0;\n  margin-left: var(--tab-width);\n  overflow-y: hidden;\n}\n\n.kpi-dashboard-tabs .MuiTabs-indicator {\n  background-color: rgba(0, 128, 0, 1);\n}\n\n.kpi-dashboard-tabs .MuiTab-root {\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.kpi-dashboard-tabs .MuiTab-root svg {\n  color: rgba(0, 0, 0, 0.6);\n  font-size: 28px;\n}\n\n.kpi-dashboard-tabs .Mui-selected {\n  color: rgba(0, 128, 0, 1);\n}\n\n.kpi-dashboard-tabs .Mui-selected svg {\n  color: rgba(0, 128, 0, 1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
