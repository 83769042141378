import { KpiDashBoardMountProps } from "../../types/KpiDashboardMountProps";
import { KpiDashboardInfoData } from "../../types/KpiDashboardProps";
import { BehaviorSubject } from "rxjs";
import {
  getIconsMock,
  getStoreDetailsMock,
  getTransactionDetailsByIntervalMock,
} from "./TransactionVisibilityApiMock";
import {
  getPriceCompareDataMock,
  locationBySiteIdMock,
  findLocationProductPriceMock,
} from "./StoreProximityApiMocks";

const mockKpiReportObject: KpiDashboardInfoData = {
  isOpen: true,
  rowSelectionType: "Single",
  tab: 3,
  reports: {
    markerInfo: {
      importcode: "36752",
      title: "36752 - 110 W CENTER ST  84057",
      location: {
        lat: 40.29743,
        lon: -111.69816,
      },
      isNewKPIPin: true,
      logoSrc: "711.png",
    },
  },
};

const iconData = {
  filename: "711.png",
  iconid: "11",
};

export const mockCustomProps: KpiDashBoardMountProps = {
  kpiReportsBehaviour: new BehaviorSubject<KpiDashboardInfoData>(
    mockKpiReportObject
  ),
  kpiMaximizeBehaviour: new BehaviorSubject<boolean>(true),
  iconDataObservable: new BehaviorSubject<any>(iconData),
  storeProximityFunctions: {
    fetchLocationPrices: (importcode: string) => {
      return new BehaviorSubject<any>(locationBySiteIdMock);
    },
    getPriceCompareData: (location: any, radius: any) => {
      return new BehaviorSubject<any>(getPriceCompareDataMock);
    },
    getPriceComparePrices: (listOfImportcodes: string[]) => {
      return new BehaviorSubject<any>(findLocationProductPriceMock);
    },
  },
  transactionVisibilityFunctions: {
    fetchListOfIcons: () => {
      return new BehaviorSubject<any>(getIconsMock);
    },
    getStoreDetails: (storeId: string) => {
      return new BehaviorSubject<any>(getStoreDetailsMock);
    },
    getTransactionDetailsBetweenDates: (
      storeId: string,
      startDate: string,
      endDate: string
    ) => {
      return new BehaviorSubject<any>(getTransactionDetailsByIntervalMock);
    },
    getTransactionDetailsByInterval: (storeId: string, interval: number) => {
      return new BehaviorSubject<any>(getTransactionDetailsByIntervalMock);
    },
  },
};
