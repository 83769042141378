import KpiDashboard from "./KpiDashboard";
import { mockCustomProps } from "./mock/KpiDashboardMockups";

export default function App(singleSpaMountProps) {
  console.log("visops-tableau-kpi: customProps", singleSpaMountProps);
  const isLocalHost = window?.location?.hostname === "localhost";

  const mockKpiDashboard = KpiDashboard(mockCustomProps);

  console.log("🚀 ~ App ~ isLocalHost:", isLocalHost);

  return KpiDashboard(
    isLocalHost ? mockCustomProps : singleSpaMountProps.customProps
  );
}
